<template>
  <section class="section">
    <RegionsIndex />
  </section>
</template>

<script>
import RegionsIndex from "@/modules/regions/components/RegionsIndex";
export default {
  name: "Regions",
  components: { RegionsIndex },
};
</script>
