<template>
  <div class="content">
    <h1 class="title">Регионы РФ</h1>

    <b-table
      :data="regions"
      :default-sort-direction="defaultSortDirection"
      :sort-icon="sortIcon"
      :sort-icon-size="sortIconSize"
      :mobile-cards="false"
    >
      <b-table-column v-slot="props" label="#">
        {{ props.index + 1 }}
      </b-table-column>
      <b-table-column v-slot="props" field="name" label="Название" sortable>
        {{ props.row.name }}
      </b-table-column>
      <b-table-column v-slot="props" field="code" label="Код" sortable>
        {{ props.row.code }}
      </b-table-column>
    </b-table>
  </div>
</template>

<script>
import { Resource } from "@/common/const/common";
import { mapGetters } from "vuex";

export default {
  name: "RegionsIndex",
  data() {
    return {
      regions: [],
      isLoaded: false,

      defaultSortDirection: "asc",
      sortIcon: "arrow-up",
      sortIconSize: "is-small",
    };
  },
  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },
  async mounted() {
    await this.loadAsyncData();
    document.title = "Регионы РФ";
  },
  methods: {
    async loadAsyncData() {
      try {
        this.regions = await this.$api.fetchData.get(
          `${Resource.REGIONS}?_sort=name:asc`
        );
      } catch (e) {
        await this.$router.push("/not-found");
      }
    },
  },
};
</script>
